import {loadPluginCss} from 'app/plugins/sdk';
import {StatusCtrl} from './status_ctrl';

loadPluginCss({
  dark: 'plugins/apica-status-panel/css/status-panel.dark.css',
  light: 'plugins/apica-status-panel/css/status-panel.light.css'
});

export {
  StatusCtrl as PanelCtrl
};
